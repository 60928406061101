<template>
  <div class="container" v-html="article"></div>
</template>
<script>
  import { Toast } from 'vant'
  export default {
    name: 'Policy',
    data () {
      return{
        // 网页名称
        webTitle: [
          {
            code: 'fwxy',
            name: '服务协议'
          },
          {
            code: 'yszc',
            name: '隐私政策'
          },
          {
            code: 'mzsm',
            name: '免责申明'
          },
          {
            code: 'gywm',
            name: '关于我们'
          }
        ],
        articleCode: null, // 文章id
        article: [] // 文章内容
      }
    },
    created(){
    },
    mounted(){
      console.log('协议详情')
      console.log('文章code：'+this.$route.query.code)
      this.articleCode = this.$route.query.code
      // 修改网页名称
      var i;
      for(i in this.webTitle){
        if(this.webTitle[i].code == this.articleCode){
          document.title = this.webTitle[i].name
        }
      }
      // document.title = this.webTitle.this.$route.query.code // 修改网页名称
      this.getDetail()
    },
    methods: {
      getDetail() {
        var me = this;
        this.$get({
          url: 'content/findByCode',
          params: {
            token: this.$global.token,
            code: this.articleCode
          }
        }).then(rsp => {
          me.article = rsp.content
        }).catch( error => {
            Toast(error.message)
        })
      }
    }
  }
</script>
<style scoped lang="scss">
  .container{
    width: 100%;
    height: auto;
    min-height: 100vh;
    padding: 20px;
    box-sizing: border-box;
  }
</style>
